import React, { useEffect } from "react"
import { useCartContext } from "../../../providers/cart"
import { useCustomerContext } from "../../../hooks/useCustomer"

export const withDetails = Component => ({ name = "Details", page, uri, location }) => {
  const { customer } = useCustomerContext()
  const { cart, gotoCheckout, giftRegistryCheckoutHandler } = useCartContext()

  const { title } = page

  const paths = [
    {
      title,
      url: uri,
    },
  ]

  useEffect(() => {
    async event => {
      if (location?.state?.checkout && customer?.id) {
        const giftRegistryCheckout = await giftRegistryCheckoutHandler()
        gotoCheckout(event, giftRegistryCheckout?.addressPrefill, giftRegistryCheckout?.registryItemsInCart)
      }
    }
  }, [location, customer, gotoCheckout, giftRegistryCheckoutHandler, cart?.checkoutUrl])

  Component.displayName = name
  return <Component uri={uri} paths={paths} />
}
